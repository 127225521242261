exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auto-cc-privacy-js": () => import("./../../../src/pages/AutoCC/privacy.js" /* webpackChunkName: "component---src-pages-auto-cc-privacy-js" */),
  "component---src-pages-auto-cc-terms-js": () => import("./../../../src/pages/AutoCC/terms.js" /* webpackChunkName: "component---src-pages-auto-cc-terms-js" */),
  "component---src-pages-how-to-remove-vocals-js": () => import("./../../../src/pages/how_to_remove_vocals.js" /* webpackChunkName: "component---src-pages-how-to-remove-vocals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-ru-js": () => import("./../../../src/pages/ru.js" /* webpackChunkName: "component---src-pages-ru-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-ua-js": () => import("./../../../src/pages/ua.js" /* webpackChunkName: "component---src-pages-ua-js" */),
  "component---src-pages-video-saver-privacy-js": () => import("./../../../src/pages/VideoSaver/privacy.js" /* webpackChunkName: "component---src-pages-video-saver-privacy-js" */),
  "component---src-pages-video-saver-terms-js": () => import("./../../../src/pages/VideoSaver/terms.js" /* webpackChunkName: "component---src-pages-video-saver-terms-js" */)
}

